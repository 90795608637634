import * as React from "react";
import { Like } from "./Like";
import { Button } from "./Button";
import { adjustedAccentColor } from "../../utility/Utility";

export var OffcanvasInstance: Offcanvas | null = null;

class Offcanvas {
  animState: [animationState: string, setAnimationState: (_: string) => void];
  setBody: (_: React.ReactNode) => void;
  setHeader: (_: React.ReactNode) => void;
  setLike: (_: React.ReactNode) => void;

  constructor(
    animState: [animationState: string, setAnimationState: (_: string) => void],
    setBody: (_: React.ReactNode) => void,
    setHeader: (_: React.ReactNode) => void,
    setLike: (_: React.ReactNode) => void,
  ) {
    this.animState = animState;
    this.setBody = setBody;
    this.setHeader = setHeader;
    this.setLike = setLike;
  }

  private reveal() {
    this.animState[1]("top-[0%]");
  }

  public async hide() {
    this.animState[1]("top-full");
  }

  public show(props: {
    header?: React.ReactNode;
    body: JSX.Element | string;
    likeID?: string;
  }) {
    if (props.likeID) {
      this.setLike(<Like key={props.likeID} identifier={props.likeID} />);
    }

    this.setBody(
      <div className="flex grow flex-col gap-2">
        {typeof props.body == "string" ? (
          <div
            className="container flex h-full min-h-full grow flex-col"
            dangerouslySetInnerHTML={{ __html: props.body as string }}
          ></div>
        ) : (
          <div className="container flex h-full min-h-full grow flex-col">
            {props.body}
          </div>
        )}
      </div>,
    );
    this.reveal();
  }
}

export const OffcanvasComponent = () => {
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const offcanvasRef = React.useRef<HTMLDivElement>(null);

  const [animationState, setAnimationState] = React.useState("top-full");
  const [like, setLike] = React.useState<React.ReactNode>(null);
  const [body, setBody] = React.useState<React.ReactNode>(null);

  const accent = document.documentElement.style.getPropertyValue("--dm-accent");

  React.useEffect(() => {
    OffcanvasInstance = new Offcanvas(
      [animationState, setAnimationState],
      setBody,
      (_) => {},
      setLike,
    );
  }, []);

  return (
    <div
      className={
        "fixed bottom-0 left-0 right-0 flex flex-col justify-end overflow-hidden bg-neutral-900/80 text-jgu-black transition-[top] duration-500 ease-in-out " +
        animationState
      }
    >
      <div
        className={
          "m-0 flex h-[85vh] max-h-[85vh] flex-col from-jgu-orange-20 to-jgu-purple-20 p-0"
        }
        tabIndex={-1}
        style={{
          backgroundImage: `linear-gradient(to top right, ${adjustedAccentColor(accent, 100)} , var(--tw-gradient-from), var(--tw-gradient-to))`,
        }}
        ref={offcanvasRef}
      >
        <div className="flex h-12 items-center justify-center">
          <div className="h-100 container flex flex-row items-center justify-center pb-2 pe-3 pl-3 pr-3 pt-2">
            <Button
              theme="transparent"
              onClick={() => {
                OffcanvasInstance!.hide().then(() => {
                  setBody(null);
                });
              }}
            >
              <i className="bi bi-chevron-compact-down text-3xl"></i>
            </Button>
          </div>
        </div>

        <div className="grow overflow-y-auto">
          <div className="flex h-fit min-h-full w-full flex-col items-center justify-center">
            <div className="container flex grow flex-col p-2" ref={bodyRef}>
              {body}
            </div>
            {like ? (
              <div className="container flex justify-center pb-3 pl-3 pr-1 pt-3 md:pl-0 md:pr-0">
                {like}
                <hr></hr>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
