import { LitteraProvider } from "@assembless/react-littera";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./WebApp";
import { AuthProvider } from "../sources/utility/Authentication";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// dynamic style imports
import("../styles/style.css");
import("../styles/fonts.scss");
import("weather-icons/css/weather-icons.min.css");
import "erudus-icons/dist/css/style.css";
import("bootstrap-icons/font/bootstrap-icons.css");

import * as IDB from "idb-keyval";

declare const __APP_VERSION__: string;

export const InitStorage = async () => {
  console.log("Monitor", __APP_VERSION__);
  // clear local storage and idb if app version changes
  if (localStorage.getItem("fetch#" + __APP_VERSION__) == null) {
    // clear local storage and idb
    await IDB.clear();
    localStorage.clear();
    localStorage.setItem("fetch#" + __APP_VERSION__, "true");
  }
};

const queryClient = new QueryClient();

await InitStorage();

const initialLocale = location.pathname.match(/\/de\//) ? "de_DE" : "en_US";
ReactDOM.createRoot(document.getElementById("react-root")!).render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <LitteraProvider
        locales={["de_DE", "en_US"]}
        initialLocale={initialLocale}
      >
        <Router>
          <App></App>
        </Router>
      </LitteraProvider>
    </AuthProvider>
  </QueryClientProvider>,
);
